<template>
  <main-template>
    <secondary-pages-background />
    <div class="p-refusal p-other">
      <div class="container-other">
        <div class="wrapper">
          <h1 class="section-title ta--center">
            Отказ от сервиса
          </h1>
          <div class="divider" />
          <p class="mb-l ta--center">
            ВАЖНО: Клиент имеет право отказаться от оплаченного Пакета услуг в течение 14 (четырнадцати) календарных дней с момента его оплаты!!!
          </p>
          <p class="fs--medium fw--bold mb-m">
            В случае не направления Заявления в установленные сроки или с нарушением сроков, сервис считается оказанным в полном объеме, а денежные средства возврату не подлежат.
          </p>
          <p class="fs--medium fw--bold mb-m">
            ДЛЯ ОТКАЗА ОТ ПАКЕТА УСЛУГ ВАМ НЕОБХОДИМО:
          </p>
          <ul class="refusal-list mb-m">
            <li class="mb-m">
              Cкачать Заявление, пройдя по ссылке «Заявление на возврат Пакета услуг»
            </li>
            <li class="mb-m">
              Заполнить Заявление на бумажном носителе, проставив дату заполнения и личную подпись
            </li>
            <li class="mb-m">
              Заявление необходимо направить в адрес компании CLICKTOID (117133, г. Москва, ул Академика Варги, дом 8, к. 1, этаж 7, офис 703) почтой России/курьерской службой, заказным письмом с уведомлением или привезти лично
            </li>
            <li class="mb-m">
              В течение 10 (десять) календарных дней с момента получения соответствующего Заявления, компания CLICKTOID произведет возврат денежных средств Клиенту.
            </li>
          </ul>
          <p class="mb-m">
            Отказ Клиента от оплаченного Пакета услуг не предполагает возврат Клиентом Пакета в компанию
          </p>
          <p class="mb-m">
            Обращаем Ваше внимание, что компания CLICKTOID не несет ответственности за неверно указанные Клиентом реквизиты, для перечисления денежных средств.
          </p>
          <app-button
            class="btn-download mb-l"
            title="Скачать «Заявление на возврат Пакета услуг»"
            description="DOCX, 12 KB"
            href="/cabinet/refusal">
            <icon-arrow-right class="icon" />
          </app-button>
        </div>
      </div>
    </div>
  </main-template>
</template>

<script>
import MainTemplate from '@/layouts/MainTemplate'
import AppButton from '@/components/common/AppButton'
import SecondaryPagesBackground from '@/components/SecondaryPagesBackground'
import IconArrowRight from '@/assets/img/icons/arrow-right.svg'

export default {
  name: 'Refusal',
  components: {
    MainTemplate,
    AppButton,
    SecondaryPagesBackground,
    IconArrowRight
  }
}
</script>

<style lang="scss" scoped>
  .p-refusal {
    .wrapper {
      max-width: 640px;
      .btn-download {
        .icon {
          transform: rotate(90deg);
        }
      }
    }
  }
</style>